//Theme variables
$theme-name: 'eswelt';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #00352B;
$color-primary-dark: #00251E;
$color-secondary: #4ACD8A;
$color-tertiary: rgba(250, 255, 0, 0.4);
//Overlay solid
$color-overlay-solid-bottom: rgba($color-black, 0.35);
$color-overlay-solid-top: rgba(0, 53, 43, 0.45);
$color-overlay-multiply: rgba($color-primary-dark, 0.65);
$color-overlay-difference: rgba($color-primary-dark, 0.65);
$color-overlay-color: $color-tertiary;

//Overlay gradient
$color-overlay-gradient-bottom: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 13.02%, rgba(0, 0, 0, 0.35) 100%);
$color-overlay-gradient-multiply: linear-gradient(180deg, rgba(0, 37, 30, 0.00) 0%, rgba(0, 37, 30, 0.14) 19.27%, rgba(0, 37, 30, 0.65) 100%);
$color-overlay-gradient-difference: linear-gradient(180deg, rgba(0, 37, 30, 0.00) 0%, rgba(0, 37, 30, 0.14) 19.27%, rgba(0, 37, 30, 0.65) 100%);
$color-overlay-gradient-top: linear-gradient(180deg, rgba(0, 53, 43, 0.00) 0%, rgba(0, 53, 43, 0.06) 19.27%, rgba(0, 53, 43, 0.45) 100%);
$color-overlay-gradient-rotate: linear-gradient(90deg, rgba(0, 53, 43, 0.00) 0%, rgba(0, 53, 43, 0.06) 19.27%, rgba(0, 53, 43, 0.45) 100%);
$color-overlay-gradient-multiply-rotate: linear-gradient(90deg, rgba(0, 37, 30, 0.00) 0%, rgba(0, 37, 30, 0.14) 19.27%, rgba(0, 37, 30, 0.65) 100%);
$color-overlay-gradient-difference-rotate: linear-gradient(90deg, rgba(0, 37, 30, 0.00) 0%, rgba(0, 37, 30, 0.14) 19.27%, rgba(0, 37, 30, 0.65) 100%);
$color-light: #D2D2C8;
$color-light-50: #E9E9E3;

$color-error: #E91A1B;
$color-form-borders: $color-light;
$color-menu-bg: #F0F0F0;

//Text colors
$color-text: $color-white;
$color-text-light: #5E5E5E;
$color-text-dark: #323232;

//Font variables
$mainfont: 'Inter';

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-max-breakout: ceil(($default-size * 10)); // ~160px
$heading-h1-max: ceil(($default-size * 7)); // ~112px
$heading-h1-min: ceil(($default-size * 2.1875)); // ~35px
$heading-h2-max-breakout: ceil(($default-size * 8.75)); // ~140px
$heading-h2-max: ceil(($default-size * 6.5)); // ~104px
$heading-h2-min: ceil(($default-size * 2)); // ~32px
$heading-h3-max-breakout: ceil(($default-size * 6.5)); // ~104px
$heading-h3-max: ceil(($default-size * 4.75)); // ~76px
$heading-h3-min: ceil(($default-size * 2)); // ~32px
$heading-h4-max: ceil(($default-size * 3.5)); // ~56px
$heading-h4-min: ceil(($default-size * 1.5)); // ~24px
$heading-h5-min: ceil(($default-size * 2.25)); // ~36px
$heading-h5-max: ceil(($default-size * 1.5)); // ~24px
$heading-h6: ceil(($default-size * 1)); // ~16px

//Text
$font-l-max: ceil(($default-size * 2)); // ~32px
$font-l-min: ceil(($default-size * 1.125)); // ~18px
$font-m-max: ceil(($default-size * 1.5)); // ~24px
$font-m-min: ceil(($default-size * 1)); // ~16px
$font-s-max: ceil(($default-size * 1.125)); // ~18px
$font-s-min: ceil(($default-size * 1)); // ~16px
$font-xs-max: ceil(($default-size * 0.9375)); // ~15px
$font-xs-min: ceil(($default-size * 0.875)); // ~14px
$font-menu: ceil(($default-size * 1.125)); // ~18px
$font-form: ceil(($default-size * 0.75)); // ~12px

//Other text styles
$font-quote-max: ceil(($default-size * 3.5)); // ~56px
$font-quote-min: ceil(($default-size * 1.5)); // ~24px
$font-buttons: ceil(($default-size * 0.8125)); // ~13px
$font-menu: ceil(($default-size * 1.25)); // ~20px
$font-topmenu: ceil(($default-size * 0.9375)); // ~15px

//Font settings
$text-settings: (
        'font-heading-h1-breakout': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 8vw, $heading-h1-max-breakout),
                font-weight: $semibold,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 8vw, $heading-h1-max),
                font-weight: $semibold,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h2-breakout': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 7vw, $heading-h2-max-breakout),
                font-weight: $medium,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 7vw, $heading-h2-max),
                font-weight: $medium,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h3-breakout': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 6vw, $heading-h3-max-breakout),
                font-weight: $medium,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 6vw, $heading-h3-max),
                font-weight: $medium,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $medium,
                line-height: 125%,
                text-transform: uppercase
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: clamp($heading-h5-min, 4vw, $heading-h5-max),
                font-weight: $medium,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $semibold,
                line-height: 100%,
                text-transform: uppercase
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: clamp($font-l-min, 4vw, $font-l-max),
                line-height: 150%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: clamp($font-m-min, 4vw, $font-m-max),
                line-height: 150%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: clamp($font-s-min, 4vw, $font-s-max),
                line-height: 150%,
        ),
        'font-text-xs': (
                font-family: $mainfont,
                font-size: clamp($font-xs-min, 4vw, $font-xs-max),
                line-height: 120%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                line-height: 125%,
        )
);

;@import "sass-embedded-legacy-load-done:1932";